var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{staticClass:"pb-3",attrs:{"title":_vm.title}}),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"item-key":"tareaId","no-data-text":"No hay tareas programadas","items":_vm.tareasProgramadas,"calculate-widths":"","loading-text":"Cargando datos","expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.habilitada",fn:function(ref){
var item = ref.item;
return [(item.habilitada)?_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModalEditTareaProgramacion(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar tarea")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.verHistorialTareas(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver historial")])])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(isExpanded ? "mdi-account-check" : "mdi-account"))])]}}],null,true)},[_c('span',[_vm._v("Usuarios")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',{staticStyle:{"padding-left":"0px"}},[_vm._v("Fecha modificación:")]),_vm._v(" "+_vm._s(item.fechaModi)+" "),_c('strong',{staticStyle:{"padding-left":"110px"}},[_vm._v("Usuario modificación:")]),_vm._v(" "+_vm._s(item.usuModi)+" ")])]}}],null,true)})],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"to-right pt-7 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1),(_vm.openModalSeeLog)?_c('v-dialog',{attrs:{"max-width":"60%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.openModalSeeLog),callback:function ($$v) {_vm.openModalSeeLog=$$v},expression:"openModalSeeLog"}},[_c('VerLogTareasProgramadas',{attrs:{"tareaId":_vm.tareaId,"tareaNombre":_vm.tareaNombre},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e(),(_vm.openModalEdit)?_c('v-dialog',{attrs:{"max-width":"63%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.openModalEdit),callback:function ($$v) {_vm.openModalEdit=$$v},expression:"openModalEdit"}},[_c('EditTareasProgramadas',{attrs:{"itemTarea":_vm.itemTarea},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }