<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-data-table
            :headers="headers"
            item-key="tareaId"
            no-data-text="No hay tareas programadas"
            :items="tareasProgramadas"
            calculate-widths
            loading-text="Cargando datos"
            class="elevation-1"
            :expanded.sync="expanded"
            show-expand
          >
            <template v-slot:[`item.habilitada`]="{ item }">
              <v-icon
                v-if="item.habilitada"
                small
                color="primary"
                class="d-flex justify-center"
              >
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalEditTareaProgramacion(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar tarea</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="verHistorialTareas(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver historial</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong style="padding-left:0px;">Fecha modificación:</strong>
                {{ item.fechaModi }}
                <strong style="padding-left:110px;"
                  >Usuario modificación:</strong
                >
                {{ item.usuModi }}
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalSeeLog"
      v-model="openModalSeeLog"
      max-width="60%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <VerLogTareasProgramadas
        :tareaId="tareaId"
        :tareaNombre="tareaNombre"
        @closeAndReload="closeAndReload"
      ></VerLogTareasProgramadas>
    </v-dialog>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="63%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditTareasProgramadas
        :itemTarea="itemTarea"
        @closeAndReload="closeAndReload"
      ></EditTareasProgramadas>
    </v-dialog>
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import VerLogTareasProgramadas from "@/components/modules/administracion-sistema/VerLogTareasProgramadas.vue";
import EditTareasProgramadas from "@/components/modules/administracion-sistema/EditTareasProgramadas.vue";
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "TareasProgramadas",
  components: {
    GoBackBtn,
    PageHeader,
    EditTareasProgramadas,
    VerLogTareasProgramadas,
    Ayuda
  },
  data() {
    return {
      title: "Tareas programadas",
      showExpand: false,
      showHelp: false,
      showIcon: true,
      optionCode: enums.webSiteOptions.TAREAS_PROGRAMADAS,
      rules: rules,
      routeToGo: "ConfiguracionAdmSistema",
      seeIcon: enums.icons.SEE,
      checkIcon: enums.icons.CHECK_OUTLINE,
      editIcon: enums.icons.EDIT,
      tareasItem: [],
      openModalEdit: false,
      isFormValid: true,
      canEdit: true,
      tareaId: null,
      tareaNombre: null,
      openModalSeeLog: false,
      tareaNom: null,
      itemTarea: {},
      selectedTime: null,
      showTimePicker: false,
      tareasProgramadas: [],
      expanded: [],
      headers: [
        {
          text: "Tarea",
          align: "left",
          value: "tareaNombre",
          sortable: false
        },
        {
          text: "Descripción",
          align: "left",
          value: "tareaDescrip",
          sortable: false
        },
        {
          text: "Habilitada",
          align: "center",
          value: "habilitada",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
          align: "start",
          width: "1%"
        }
      ],
      allowedActions: null
    };
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadTareas();
  },
  methods: {
    ...mapActions({
      getTareas: "AdministracionSistema/getTareas"
    }),
    async loadTareas() {
      const response = await this.getTareas();
      this.tareasProgramadas = response;
    },
    verHistorialTareas(item) {
      this.tareaId = item.tareaId;
      this.tareaNombre = item.tareaNombre;
      this.openModalSeeLog = true;
    },
    openModalEditTareaProgramacion(item) {
      this.openModalEdit = true;
      this.itemTarea = item;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.openModalSeeLog = false;
      this.loadTareas();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
